<template>
  <div>
    <b-card header-tag="header" style="min-height: 750px;">
      <b-row>
        <b-col cols="12">
          <b-card bg-variant="primary" style="margin-bottom: 0px; background-color: #3c9ca5!important;" no-body>
            <b-card-header header-bg-variant="success" header-text-variant="dark" class="p-1">
              <i class="fa fa-file-text-o"></i> WHS üzerinden güncelleme işlemleri
            </b-card-header>
            <template v-if="forbidden_to_whs">
              Bu sayfa WHS ana sisteminde kullanılamaz
            </template>
            <template v-else>
              <b-row style="padding: 3px;">
                <b-col v-if="user.permissions_result && user.permissions_result.indexOf('wisdom') !== -1" sm="12" md="4">
                  <b-card class="card-accent-secondary" no-body style="color: black; margin-bottom: 0px;">
                    <b-card-header header-bg-variant="success" header-text-variant="dark" class="p-1">
                      <i class="fa fa-search"></i> Güncelleme veri tipi seçimi
                    </b-card-header>
                    <b-row>
                      <b-col cols="3"> Veri Tipi </b-col>
                      <b-col cols="9">
                        <b-form-select v-model="update_type">
                          <option value="update_all">Verilerin Tümü</option>
                          <option value="update_selected">Seçili Veriler</option>
                          <option value="update_wdm">Tüm WDM leri Güncelle</option>
                          <option value="update_wisdomera_system">WisdomEra System dökümanını güncelle</option>
                        </b-form-select>
                      </b-col>
                    </b-row>
                  </b-card>
                </b-col>
                <b-col v-if="user.permissions_result && user.permissions_result.indexOf('wisdom') !== -1" sm="12" md="4" style="border-radius: 15px">
                  <b-card class="card-accent-secondary" no-body style="color: black; margin-bottom: 0px;">
                    <b-card-header header-bg-variant="success" header-text-variant="dark" class="p-1">
                      <i class="fa fa-database"></i> Seçili Veri Tipleri
                      <b-button class="pull-right" variant="warning" size="sm" @click="f_selectAllModels()"><i class="fa fa-check"></i></b-button>
                    </b-card-header>
                    <b-row v-if="parent_list.length > 0 && update_type === 'update_selected'">
                      <b-col cols="12">
                        <div id="s_operationsScrollbar" style="min-height: 120px; max-height: 150px; overflow-y: scroll; overflow-x: hidden; border-radius: 10px 0 0 10px; padding: 3px">
                          <template v-for="(parent_data, parent_data_ind) in parent_list">
                            <b-row :class="f_calculateWdmClass(parent_data.parent)" @click="f_addRemoveWdm(parent_data.parent)">
                              <b-col cols="12">
                                <i :class="[selected_parent_list.indexOf(parent_data.parent) !== - 1 ? 'fa fa-check' : 'fa fa-caret-right']"></i> {{ parent_data.name ? parent_data.name : parent_data.parent }}
                              </b-col>
                            </b-row>
                          </template>
                        </div>
                      </b-col>
                    </b-row>
                    <b-row v-else-if="update_type === 'update_all'">
                      <b-col cols="12">Tüm Veriler</b-col>
                    </b-row>
                  </b-card>
                </b-col>
                <b-col sm="12" md="4">
                  <b-card class="card-accent-secondary" no-body style="color: black; margin-bottom: 0px;">
                    <b-card-header header-bg-variant="success" header-text-variant="dark" class="p-1">
                      <i class="fa fa-asterisk"></i>
                    </b-card-header>
                    <b-row>
                      <b-col cols="12">
                        <b-button variant="primary" size="md" style="width: 100%;" @click="f_updateLimitedPerm('update_selected', ['wdm2'])"><i class="fa fa-info"></i> Yeni ilaçları aktar </b-button>
                        <b-button variant="secondary" size="md" style="width: 100%;" @click="f_updateLimitedPerm('update_selected', ['wisdom_data_model_3'])"><i class="fa fa-info"></i> İlaç bilgilerini aktar </b-button>
                        <b-button variant="warning" size="md" style="width: 100%;" @click="f_updateLimitedPerm('update_selected', ['wdm16', 'lang_id_list'])"><i class="fa fa-info"></i> Dil güncelleştirmelerini aktar </b-button>
                        <b-button variant="success" size="md" style="width: 100%;" @click="f_updateLimitedPerm('update_wdm', [])"><i class="fa fa-info"></i> Tüm WDM leri güncelle </b-button>
                        <b-button variant="warning" size="md" style="width: 100%;" @click="f_updateLimitedPerm('update_wdmr_and_multi_wdmr_schemas', [])"><i class="fa fa-info"></i> Tüm Wdmr ve MultiWdmr Şablonlarını Güncelle </b-button>
                      </b-col>
                    </b-row>
                  </b-card>
                </b-col>
              </b-row>
              <b-card-footer v-if="user.permissions_result && user.permissions_result.indexOf('wisdom') !== -1" footer-bg-variant="primary" header-text-variant="dark" class="p-1" style="background-color: #5db5bd!important;">
                <b-row>
                  <b-col sm="12" md="4">
                  </b-col>
                  <b-col sm="12" md="4">
                    <b-button variant="warning" size="md" style="width: 100%;" @click="f_updateallFromWhs()"><i class="fa fa-info"></i> Güncelle </b-button>
                  </b-col>
                  <b-col sm="12" md="4">
                  </b-col>
                </b-row>
              </b-card-footer>
            </template>
          </b-card>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import WebService from '@/services/webservice';
import OptionService from '@/services/option';
import {
  default as Modal
} from '@/components/widgets/Modal';

export default {
  name: 'WhsApi',
  components: {
    Modal
  },
  computed: {
    ...mapGetters({
      patient: 'patient_selected',
      device: 'device'
    })
  },
  data () {
    return {
      user: {},
      parent_list: [],
      selected_parent_list: [],
      update_type: 'update_all',
      wisdomera_system: {},
      forbidden_to_whs: false
    }
  },
  created: function () {
    this.user = JSON.parse(localStorage.getItem('user'));
    this.wisdomera_system = JSON.parse(localStorage.getItem('wisdomera_system'));
    if (this.wisdomera_system.system_type === 'mainserver') {
      this.forbidden_to_whs = true;
    }
    OptionService.getOptionParentList()
      .then(resp => {
        if (resp.data.status === 'success') {
          this.parent_list = resp.data.result;
        } else {
          alert('error ', resp.data.message);
        }
      })
  },
  mounted: function () {
    // this.TestSpeedWithWisdomDataModals();
  },
  methods: {
    f_updateLimitedPerm: function (update_type, selected_parent_list) {
      this.update_type = update_type;
      this.selected_parent_list = selected_parent_list;
      this.f_updateallFromWhs();
    },
    f_selectAllModels: function () {
      if (this.selected_parent_list.length > 0) {
        this.selected_parent_list = [];
      } else {
        for (let i in this.parent_list) {
          this.selected_parent_list.push(this.parent_list[i]['parent']);
        }
      }
    },
    f_addRemoveWdm: function (parent) {
      if (this.selected_parent_list.indexOf(parent) !== -1) {
        this.selected_parent_list.splice(this.selected_parent_list.indexOf(parent), 1);
      } else {
        this.selected_parent_list.push(parent);
      }
      this.$forceUpdate();
    },
    f_calculateWdmClass: function (model_value) {
      if (this.selected_parent_list.indexOf(model_value) !== -1) {
        return 's_rowSelected';
      } else {
        return 's_rowNotSelected';
      }
    },
    f_updateallFromWhs: function () {
      let data = { 'parent_list': this.selected_parent_list, 'update_type': this.update_type };
      this.$store.commit('Loading', { 'status': true, 'data': { 'label': 'Veriler Güncelleniyor.' } });
      WebService.make_request_to_get_data_from_whs(data)
        .then(resp => {
          if (resp.data.status === 'success') {
            if (this.update_type === 'update_wisdomera_system') {
              localStorage.setItem('wisdomera_system', JSON.stringify(resp.data.result));
            }
            alert('Başarılı ', resp.data.message);
          } else {
            alert('Error: ', resp.data.message);
          }
          this.$store.commit('Loading', { 'status': false, 'data': {} });
        });
    }
  },
  watch: {}
}

</script>

<style type="text/css">
.s_rowSelected {
  background-color: #9effc08f;
  cursor: pointer;
  color: black;
  border-bottom: solid 0.5px #00000075;
}

.s_rowSelected:hover {
  background-color: #9effc08f;
  cursor: pointer;
  color: black;
  border-bottom: solid 0.5px #00000075;
}

.s_rowNotSelected {
  background-color: white;
  cursor: pointer;
  color: black;
  border-bottom: solid 0.5px #00000075;
}

.s_rowNotSelected:hover {
  background-color: #e6e6e6;
  cursor: pointer;
  color: black;
  border-bottom: solid 0.5px #00000075;
  /*border-bottom: solid 1px #e6e6e6;*/
}

</style>

